<div class="column w-full position-relative">
  <textarea
    [id]="uniqueId"
    spellcheck="false"
    [(ngModel)]="value"
    (ngModelChange)="value = $event; onChange($event)"
    [placeholder]="placeholder"
    (blur)="blur.emit()"
    [rows]="rows"
    [disabled]="isDisabled"
    [maxlength]="maxLength"
  ></textarea>

  @if (control && control.invalid && (control.touched || control.dirty) &&
  control.errors) {
  <span class="font-error font-validation pt-4 pl-1">
    @if (control.errors['required']) {Campo obrigatório!} @if
    (control.errors['minlength']) {Mínimo
    {{ control.errors["minlength"].requiredLength }} caracteres!} @if
    (control.errors['maxlength']) {Máximo
    {{ control.errors["maxlength"].requiredLength }} caracteres!}
  </span>
  }
</div>
