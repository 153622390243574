import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { TValue } from '../../../core/interfaces/generic.interface';

@Component({
  selector: 'app-textarea',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent implements ControlValueAccessor, OnInit {
  @Input() value: TValue = '';
  @Input() placeholder: string = '';
  @Input() rows: string = '3';
  @Input() control!: FormControl<TValue> | AbstractControl<TValue>;
  @Input() openFocus: boolean = false;
  @Input() maxLength: number = 1000;

  @Output() blur = new EventEmitter<void>();

  isDisabled = false;
  uniqueId = crypto.randomUUID();

  ngOnInit(): void {
    if (this.openFocus) {
      this.focus();
    }
  }

  blurEvent() {
    this.blur.emit();
  }

  writeValue(value: TValue): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onChange = (value: TValue) => {};

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onTouched = () => {};

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  focus() {
    setTimeout(() => {
      document.getElementById(this.uniqueId)?.focus();
    }, 100);
  }
}
